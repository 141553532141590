<template>
  <tr class="price-tr">
    <td>{{ price.amount }}</td>
    <td>{{ price.createdAt|systemDateTime }}</td>
    <td> <show-boolean :value="price.actual"/></td>
    <td> <show-boolean :value="price.principal"/> </td>
    <td> <show-boolean :value="price.discount"/> </td>
    <td> {{ price.createBy }}</td>
    <td>
        <!-- {{ price }} -->
          <a 
            href="javascript:void(0)" 
            @click.prevent="deletePrice" 
            class="btn btn-xs btn-outline-danger btn-rounded"
        >
            <i class="las la-times"></i>
        </a>
    </td>
  </tr>
</template>

<script>

import { DELETE_PRODUCT_PRICE } from '../../../graphql/market';
import ShowBoolean from '../../ShowBoolean.vue';
export default {
    components: {ShowBoolean},
    props: {
        price: {type: Object, required: true}
    },
    data(){
        return {

        }
    },
    methods: {
        deletePrice(){
            this.$apollo.mutate({
                mutation: DELETE_PRODUCT_PRICE,
                variables: {
                    "uid": this.price.uid
                },
                update: (data) => {
                    console.log(`Product price delete successfully on uid ${data.uid}`)
                }
              }).catch((error) => {
                console.error(error)
            })
        }
    }

}
</script>

<style lang="scss">
.price-tr{
    font-size: 10px !important;
    td{
        font-size: 10px !important;
    }
}
</style>