<template>
    <div>
        <i class="las la-2x" :class="value? `la-check yes` : `la-times no`"></i>
    </div>
</template>

<script>
export default {
    props: {
        value: { type: Boolean, required: true}
    }

}
</script>

<style lang="scss" scoped>
    .yes {
        color: #1C6100
    }
    .no {
        color: #B50404
    }
</style>