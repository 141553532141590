<template>
  <div>
        {{ libelle }}
        <a href="javascript:void(0)"><i class="las la-times"></i></a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        tagUid: {type: String, required: true}
    },
    data(){
        return {

        }
    },
    computed: {
        ...mapGetters({
            tags: 'market/filtres'
        }),
        tag(){
            console.log(this.tagUid)
            let t = this.tags.find(item => item.uid === this.tagUid)
            return t
        },
        libelle(){
            return this.hasTag ?  this.tag.libelle : '-'
        },
        hasTag(){
            return this.tag !== null && this.tag !== undefined
        }
    }

}
</script>

<style>

</style>