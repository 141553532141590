<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <div class="card-box" v-if="hasProduct">
      <div class="row">
        <div class="col-5">
          <img :src="photoPrincipale" width="80%" height="400px" />
          <div class="row">
            <div class="col-12">
              <a v-for="(p, index) in photos" :key="index">
                <img :src="p.url" width="120px" height="120px"/>
              </a>
            </div>
          </div>
        </div>
        <div class="col-7">
          <h5 class="pb-4">{{ product.libelle }}</h5>
          <div class="row">
            <div class="col-12">
              <label>Type: </label> {{ type }}
            </div>
            <div class="col-12">
              <label>Categorie: </label> {{ categorie }}
            </div>
            <div class="col-12">
              <label>Quantité: </label> {{ product.quantity }}
            </div>
            <div class="col-12">
              <label>Prix: </label> {{ product.actualAmount }} Fcfa
              <a href="javascript:void(0)" class="p-2"  data-toggle="modal" data-target="#add-price">
                <i class="las la-plus-circle"></i>
              </a>
              <a href="javascript:void(0)" @click.prevent="showPriceTable = !showPriceTable"><i class="las" :class="showPriceTable? `la-eye-slash`:`la-eye`" ></i></a>
              <div class="row" v-show="showPriceTable">
                <div class="col-12">
                  <table class="table table-condensed table-bordered">
                    <thead style="font-size: 10px;">
                      <th>Prix</th>
                      <th>Date</th>
                      <th>Actuel</th>
                      <th>Principal</th>
                      <th>Promotion</th>
                      <th>Created By</th>
                      <th>Actions</th>
                    </thead>
                    <tbody>
                      <price-tr v-for="(p, index) in prices" :key="index" :price="p"/>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div class="col-12">
              <label>Tags: </label> <tag v-for="(t, index) in productTags" :key="index" :tagUid="t"/>{{ product.tags }}
              <a href="javascript:void(0)" class="p-2"  data-toggle="modal" data-target="#add-tag"><i class="las la-plus-circle"></i></a>
            </div>
          </div>
          <!-- {{ product }} -->
        </div>
      </div>
      <div class="row">
        <div class="col-12 pt-5">
          <h5>Description</h5>
          <p>{{ product.description }}</p>
        </div>
      </div>
      
    </div>
    <div class="card-box" v-else>
      <div class="row">
        <div class="col-12 text-center text-bold">
          <i class="las la-exclamation-triangle la-6x"></i>
          <p> Product Not Found  <router-link :to="{name: 'market-products'}">Back to Products</router-link></p>
        </div>
        
      </div>
    </div>
    <modal title="Ajout du prix de vente" id="add-price" :footer="false">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="price">Prix</label>
            <input class="form-control" name="price" id="price" v-model="amount" />
          </div>
        </div>
        
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="actualAmount"
              id="actualAmount"
            >
            <label
              class="form-check-label"
              for="actualAmount"
            >
              Prix actuel
            </label>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="principal"
              id="principal"
            >
            <label
              class="form-check-label"
              for="principal"
            >
              Prix principal
            </label>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="discount"
              id="discount"
            >
            <label
              class="form-check-label"
              for="discount"
            >
              Prix promotionnel
            </label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mt-20">
          <div class="form-group text-right">
            <a href="javascript:void(0)" class="btn btn-primary btn-sm" @click.prevent="addProductPrice"> Ajouter</a>
          </div>
        </div>
        
      </div>
    </modal>

    <modal title="Ajout de Tag" id="add-tag" :footer="false">
      <div class="row">
        <div class="col-12 text-right">
          <a href="javascript:void(0)" @click.prevent="addTag = !addTag">
            <i class="las la-2x" :class="addTag? `la-times`:`la-plus-circle`"></i>
          </a>
        </div>
      </div>
      <div class="row"  v-if="has_error">
        <div class="col-12">
          <div class="alert alert-danger">
            <p>{{ error_msg }}</p>
          </div>
        </div>
      </div>
      <div v-show="!addTag">
        <div class="row" >
          <div class="col-12">
            <div class="form-group">
              <label for="tag">Tag</label>
              <select class="form-control" v-select="{placeholder: 'Choisir le tag'}" v-model="selectingTag">
                <option v-for="(t, index) in availableTags" :key="index">{{ t.libelle }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-group text-right">
              <a href="javascript:void(0)" @click.prevent="updateTagOnProduct" class="btn btn-primary btn-xs">Enregistrer</a>
            </div>
          </div>
        </div>
      </div>
      
      
      
      <div class="row" v-show="addTag">
        <div class="col-12">
          <div class="form-group">
            <label for="add-tag">Nouveau Tag</label>
            <input type="text" name="add-tag" id="add-tag" class="form-control" v-model="newTagLibelle">
          </div>
        </div>
      </div>
      
      <div class="row" v-show="addTag">
        <div class="col-12">
          <div class="form-group text-right">
            <a href="javascript:void(0)" @click.prevent="addNewTag" class="btn btn-danger btn-xs">Ajouter</a>
          </div>
        </div>
      </div>

    </modal>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import Modal from '../../components/modal.vue';
import Navbar from '../../components/navbar.vue';
import PriceTr from '../../components/market/product/priceTr.vue';
import Tag from '../../components/market/product/tag.vue'
import { ADD_FILTRE, ADD_PRODUCT_PRICE, UPDATE_PRODUCT } from '../../graphql/market';
const logo = require('../../assets/img/logo.png')
export default {
    components: {Navbar, Modal, PriceTr, Tag},
    data(){
        return {
          navbarItems: [
              {
                libelle: 'Market Place',
                route: 'market-products'
              },
              {
                libelle: 'Détail du produit'
              }
            ],
            pageIcon: 'la-cart',
            pageTitle: 'Market Place',
            pageDescription: 'Détail du produit',
            showPriceTable: false,
            amount: null,
            actualAmount: false,
            principal: false,
            discount: false,
            has_error: false,
            error_msg: null,
            addTag: false,
            newTagLibelle: null,
            selectingTag: null
        }
    },
    methods: {
      ...mapMutations({
        setLoading: 'SET_LOADING',
        done: 'DONE'
      }),
      initProductPriceForm(){
        this.amount = null
        this.discount = false
        this.actualAmount = false
        this.principal = false
      },

      addNewTag(){
        this.$apollo.mutate({
            mutation: ADD_FILTRE,
            variables: {
                "libelle": this.newTagLibelle,
                "type": this.product.type
            },
            update: ({data}) => {
                console.log(`Tag add successfully on uid `)
                console.log(data)
            }
          }).then(() => {
            this.newTagLibelle = null
            this.has_error = false
            this.error_msg = null
            this.addTag = false
        }).catch((error) => {
            console.error(error)
            this.has_error = true
            this.error_msg = error
        })
      },

      updateTagOnProduct(){
        let tags = JSON.parse(this.product.tags)
        tags.push(this.selectingTag)
        let data = {
          // ...this.product,
          tags: JSON.stringify(tags)
        }
        console.log(data)
        this.$apollo.mutate({
            mutation: UPDATE_PRODUCT,
            variables: {
                "uid": this.product.uid,
                "product": data
            },
            update: ({data}) => {
                console.log(`Tag add successfully on uid `)
                console.log(data)
            }
          }).then(() => {
            this.selectingTag = null
            this.has_error = false
            this.error_msg = null
            this.addTag = false
            this.done()
        }).catch((error) => {
            console.error(error)
            this.has_error = true
            this.error_msg = error
        })
      },
      addProductPrice(){
        this.setLoading(true)
        let data = {
          amount: parseFloat(this.amount),
          product: this.product.uid,
          actual: this.actualAmount,
          principal: this.principal,
          discount: this.discount
        }
        this.$apollo.mutate({
                mutation: ADD_PRODUCT_PRICE,
                variables: {
                    "price": {
                        ...data
                    }
                },
                update: (data) => {
                    console.log(`Product price add successfully on uid ${data.uid}`)
                }
              }).then(() => {
                this.initProductPriceForm()
                this.done()
            }).catch((error) => {
                this.setLoading(false)
                console.error(error)
                this.has_error = true
                this.error_msg = error
            })
        console.log(data)
        this.done()
      },
    },
    computed: {
        ...mapGetters({
            types: 'market/typeProducts',
            categories: 'market/categories',
            filtres: 'market/filtres',
            products: 'market/products',
            productPrices: 'market/productPrices'
        }),
        product() {
          let p = this.products.find(item => item.uid === this.$route.params.uid)
          return p
        },
        hasProduct(){
          return this.product !== null && this.product !== undefined
        },
        hasPhoto(){
            if(this.hasProduct){
                let photos = JSON.parse(this.product.photos)
                return photos.length > 0
            }
            return false
        },
        photoPrincipale(){
            if(this.hasPhoto){
                let photos = JSON.parse(this.product.photos)
                console.log(photos)
                return photos[0].url
            }
            return logo
        },
        photos(){
          if(this.hasPhoto) return JSON.parse(this.product.photos)
          return []
        },
        categorie(){
          let c = this.categories.find(item => item.uid === this.product.categorie)
          if(c) return c.libelle
          return '-'
        },
        type(){
          let t = this.types.find(item => item.uid === this.product.type)
          if(t) return t.libelle
          return '-'
        },
        prices(){
          return [...this.productPrices].filter(item => item.product === this.product.uid)
        },
        availableTags(){
          return [...this.filtres].filter(item => item.type === this.product.type)
        },
        productTags(){
          let tags = JSON.parse(this.product.tags)
          return tags
        }
    }

}
</script>

<style>

</style>